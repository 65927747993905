const examSchedule = [
  {
    link: "https://chinmayavvdelhi.ac.in/assets/pdfs/exam-schedules/2024-25/053-PERIODIC-II-EXAMINATION-SCHEDULE-FOR-CLASS-III-TO-V.pdf",
    date: "13/11/2024",
    title: "Date Sheet for Periodic II Examination for classes III to V",
  },
  {
    link: "https://chinmayavvdelhi.ac.in/assets/pdfs/exam-schedules/2024-25/049-GUIDELINES-OF-EXAMINATION-&-PROMOTIN-2024-25-FOR-CLASSES-III-TO-V.pdf",
    date: "11/11/2024",
    title: "Guidelines of Examination & Promotion 2024-25",
  },
  {
    link: "https://chinmayavvdelhi.ac.in/assets/pdfs/exam-schedules/2024-25/Proficiency-Examinations-(X-and-XII)-2024.pdf",
    date: "19/10/2024",
    title: "Proficiency Exam X and XII",
  },
  {
    link: "https://chinmayavvdelhi.ac.in/assets/pdfs/exam-schedules/2024-25/PT3_PT2_UT2-Examinations(VI-to-IX-and-XI)-2024.pdf",
    date: "19/10/2024",
    title: "PT-2: VI to VIII & PT-3: IX",
  },
  {
    link: "https://chinmayavvdelhi.ac.in/assets/pdfs/exam-schedules/2024-25/Mid-Term-Exams-VI-to-IX-and-XI-Datesheet-2024.pdf",
    date: "26/07/2024",
    title: "Mid Term Examination for classes VI - IX & XI",
  },
  {
    link: "https://chinmayavvdelhi.ac.in/assets/pdfs/exam-schedules/2024-25/PT3-UT3-Exams-X-and-XII-Datesheet-2024.pdf",
    date: "26/07/2024",
    title: "PT III / UT III Exams X and XII Datesheet 2024",
  },
  {
    link: "https://chinmayavvdelhi.ac.in/assets/pdfs/exam-schedules/2024-25/029-MID-TERM-EXAMINATION-SCHEDULE-2.pdf",
    date: "24/07/2024",
    title: "Mid Term Examination for classes III - V",
  },
  {
    link: "https://chinmayavvdelhi.ac.in/assets/pdfs/exam-schedules/2024-25/020-DATE-SHEET_PT_UT-EXAMS-JULY_IX-TO-XII_2024-25.pdf",
    date: "27/05/2024",
    title: "PT II / UT II / UT I Examinations July 2024",
  },
  {
    link: "https://chinmayavvdelhi.ac.in/assets/pdfs/exam-schedules/2024-25/009-DATESHEET-PT-I-UT-I-EXAMS-VI-TO-X-AND-XII-2024-25.pdf",
    date: "12/04/2024",
    title: "PT I / UT I Examinations for classes VI to XII",
  },
  {
    link: "https://chinmayavvdelhi.ac.in/assets/pdfs/exam-schedules/2023-24/DATE_SHEET_CLASS_X_Revised_03012024.pdf",
    date: "29/01/2024",
    title: "DATE SHEET CLASS XII",
  },
  {
    link: "https://chinmayavvdelhi.ac.in/assets/pdfs/exam-schedules/2023-24/DATE_SHEET_CLASS_XII_Revised_03012024.pdf",
    date: "29/01/2024",
    title: "DATE SHEET CLASS X",
  },
  {
    link: "https://chinmayavvdelhi.ac.in/assets/pdfs/exam-schedules/2023-24/ANNUAL-EXAMINATION-SCHEDULE-PRIMARY.pdf",
    date: "20/01/2024",
    title: "Annual Examination Datesheet for Classes III to V",
  },
  {
    link: "https://chinmayavvdelhi.ac.in/assets/pdfs/exam-schedules/048-DATESHEET-ANNUAL-EXAMS-2023-24.pdf",
    date: "15/01/2024",
    title: "Annual Examination Datesheet for Classes VI to IX & XI",
  },
  // {
  //   link: "https://chinmayavvdelhi.ac.in/assets/pdfs/exam-schedules/039-PERIODIC-TEST-UNIT-TEST-EXAMINATION-FOR-CLASSES-VI-TO-IX-AND-XI.pdf",
  //   date: "22/11/2023",
  //   title: "PERIODIC TEST FOR CLASSES VI TO IX AND XI",
  // },
  // {
  //   link: "https://chinmayavvdelhi.ac.in/assets/pdfs/exam-schedules/038-DATE-SHEET-PRE-BOARD-EXAMINATION-FOR-CLASSES-X-AND-XII-2023.pdf",
  //   date: "22/11/2023",
  //   title: "DATE SHEET - PRE-BOARD EXAMINATION FOR CLASSES X & XII",
  // },
  // {
  //   link: "https://chinmayavvdelhi.ac.in/assets/pdfs/exam-schedules/PERIODIC-II-EXAMINATION-SCHEDULE-of-classes-III-to-V.pdf",
  //   date: "20/11/2023",
  //   title: "Periodic II Examination Schedule of Classes III to V",
  // },
  // {
  //   link: "https://chinmayavvdelhi.ac.in/assets/pdfs/exam-schedules/036-REVISED-DATESHEET-PROFICIENCY-EXAMINATION-FOR-CLASSES-X-AND-XII.pdf",
  //   date: "08/11/2023",
  //   title: "Revised Proficiency Examination Datesheet for Classes X & XII",
  // },
  // {
  //   link: "https://chinmayavvdelhi.ac.in/assets/pdfs/exam-schedules/034-DATE-SHEET-PROFICIENCY-EXAMINATION-FOR-CLASSES-X-AND-XII.pdf",
  //   date: "16/10/2023",
  //   title: "Proficiency Examination (X, XII)",
  // },
  // {
  //   link: "https://chinmayavvdelhi.ac.in/assets/pdfs/exam-schedules/Datesheet-Mid-Term-Examination-of-classes-III-to-V.pdf",
  //   date: "11/08/2023",
  //   title: "Date sheet Mid Term for classes III - V",
  // },
  // {
  //   link: "https://chinmayavvdelhi.ac.in/assets/pdfs/exam-schedules/Datesheet-Mid-Term-Examination-of-classes-VI-IX-&-XI-&-PT-UTIII-of-classes-X-&-XII.pdf",
  //   date: "08/08/2023",
  //   title:
  //     "Datesheet Mid Term Examination of classes VI-IX & XI & PT-UTIII of classes X & XII",
  // },
  // {
  //   title: "PERIODIC TEST I (CLASSES III-V)",
  //   date: "26/05/2022",
  //   link: "https://chinmayavvdelhi.ac.in/assets/pdfs/exam-schedules/Datesheet-Periodic-Test-I-(Class-III-V)-2023-24.pdf",
  // },
  // {
  //   title:
  //     "PERIODIC TEST I (CLASSES VI-VIII), PERIODIC TEST II (CLASSES IX-X), UNIT TEST I (CLASS XI) AND UNIT TEST II (CLASS XII)",
  //   date: "20/05/2022",
  //   link: "https://chinmayavvdelhi.ac.in/assets/pdfs/exam-schedules/PERIODIC-TEST-I-(CLASS-VI-VIII)-PERIODIC-TEST-II-(CLASSES-IX-X)-UNIT-TEST-I-(CLASS-XI)-AND-UNIT-TEST-II-(CLASS-XII).pdf",
  // },
  // {
  //   title: "Periodic Test I (Classes IX & X) and Unit Test I (Class XII)",
  //   date: "17/04/2023",
  //   link: "https://chinmayavvdelhi.ac.in/assets/pdfs/exam-schedules/PERIODIC-TEST-I-(CLASSES-IX&X)-AND-UNIT-TEST-I-(CLASS-XII).pdf",
  // },

  // {
  //   title: "Annual Examination Datesheet - Classes III to V",
  //   date: "20/01/2023",
  //   link: "https://chinmayavvdelhi.ac.in/assets/pdfs/exam-schedules/Annual-Examination-Datesheet-classes-III-to-V-2023.pdf",
  // },
  // {
  //   title: "Annual Examination Datesheet",
  //   date: "19/01/2023",
  //   link: "https://chinmayavvdelhi.ac.in/assets/pdfs/exam-schedules/Annual-Examination-Datesheet-2023.pdf",
  // },
  // {
  //   title:
  //     "Pre-board Examination / Periodic Test III Class IX, Class X AND Class XII",
  //   date: "07/12/2022",
  //   link: "https://chinmayavvdelhi.ac.in/assets/pdfs/exam-schedules/Datesheet-Pre-Board%20Examination-Periodic-Test-III.pdf",
  // },
  // {
  //   title: "Periodic Test/Unit Test II Examination Class VI-VIII & class XI",
  //   date: "10/11/2022",
  //   link: "https://chinmayavvdelhi.ac.in/assets/pdfs/exam-schedules/020%20-%20Periodic%20Test%20II%20for%20Classes%20VI%20to%20VII%20and%20XI.pdf",
  // },
  // {
  //   title: "Proficiency Examination Periodic Test II for classes IX, X & XII",
  //   date: "21/10/2022",
  //   link: "https://chinmayavvdelhi.ac.in/assets/pdfs/exam-schedules/017-PROFICIENCY-EXAMINATION-PERIODIC-TEST-II-for-Classes-IX-X-&-XII.pdf",
  // },
  // {
  //   title: "Date Sheet - Classes X & XII - Mid-Term Examination",
  //   date: "21/09/2022",
  //   link: "https://chinmayavvdelhi.ac.in/assets/pdfs/exam-schedules/DATE-SHEET-CLASSES-X-&-XII-MID-TERM-EXAMINATION-2022.pdf",
  // },
  // {
  //   title: "Date Sheet - Class XI - Mid-Term Examination",
  //   date: "21/09/2022",
  //   link: "https://chinmayavvdelhi.ac.in/assets/pdfs/exam-schedules/DATE-SHEET-CLASS-XI-MID-TERM-EXAMINATION.pdf",
  // },
  // {
  //   title: "Date Sheet - Classes VI to IX - Mid-Term Examination",
  //   date: "21/09/2022",
  //   link: "https://chinmayavvdelhi.ac.in/assets/pdfs/exam-schedules/DATE-SHEET-CLASSES-VI-TO-IX-MID-TERM-EXAMINATION.pdf",
  // },
  // {
  //   title: "Half Yearly Examination Schedule for Class III - V (2022 - 2023)",
  //   date: "08/09/2022",
  //   link: "https://chinmayavvdelhi.ac.in/assets/pdfs/exam-schedules/HALF-YEARLY-EXAMINATION-SCHEDULE-III-V(2022-23).pdf",
  // },
  // {
  //   title: "Half Yearly Examination Circular for Class I & II (2022 - 2023)",
  //   date: "08/09/2022",
  //   link: "https://chinmayavvdelhi.ac.in/assets/pdfs/exam-schedules/HALF-YEARLY-EXAMINATION-CIRCULAR-I-&-II(2022-23).pdf",
  // },

  // {
  //   title: "Periodic I Schedule (2022-23) for classes III - V",
  //   date: "22/07/2022",
  //   link: "https://chinmayavvdelhi.ac.in/assets/pdfs/exam-schedules/PERIODIC-I-SCHEDULE-2022-23-CLASSES-III-V.pdf",
  // },
];

export default examSchedule;
